<template>
  <div>
    <el-card style="width: 400px; float: left; height: calc(100vh - 120px);overflow-y: auto;">
      <el-tree ref="tree" :props="{isLeaf:'isLeaf'}" highlight-current :auto-expand-parent="false" :default-expanded-keys="expandedKeys" @node-collapse="handleNodeCollapse" @node-expand="handleNodeExpand" @node-click="handleNodeClick" :load="loadNode" lazy node-key="node_key" :expand-on-click-node="false">
        <template slot-scope="{data, node}">
          <span class="el-tree-node__label"><i v-if="data.icon && !node.loading" :class="data.icon" style="margin-right: 8px"></i>{{data.label}}</span>
        </template>
      </el-tree>
    </el-card>

    <div v-if="currentNode" style="margin-left: 420px;">
      <a-meta-group v-if="currentNode.type === 1 || currentNode.type === 2" :node="currentNode" @success="refresh" />
      <a-meta v-else-if="currentNode.type === 3" :node="currentNode" @success="refresh" />
      <a-meta-compose-group v-else-if="currentNode.type === 4" :node="currentNode" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import AMetaGroup from './MetaGroup';
import AMeta from './Meta';
import AMetaComposeGroup from './MetaComposeGroup';
export default {
  components: { AMetaGroup, AMeta, AMetaComposeGroup },
  data() {
    return {
      currentNode: null,
      expandedKeys: [''],
      tree: [],
      initCurrentKey: null,
    }
  },
  async created() {
    this.refresh();
    const { id, type } = this.$route.query;
    if(type === 'meta' && id) {
      const nodes = await this.$api.execute('meta.get_path', id);
      
      const keys = _(nodes).map(_.cond([
        [_.matches({ type: 2 }), o => `${o.type}-${o.id}`],
        [_.matches({ type: 3 }), (o, idx) => o.model_show_compose_group ? [`${o.type}-${o.id}`, `4-${o.id}-${nodes[idx + 1].model_id}`] : `${o.type}-${o.id}`]
      ])).flatten().value();

      this.expandedKeys = _.initial(keys);
      this.initCurrentKey = _.last(keys);
      this.currentNode = _.last(nodes)
      
    }
  },
  methods: {
    async refresh() {
      // const { list: meta_group } = await this.$api.execute('meta_group.list');
      // const { list: meta } = await this.$api.execute('meta.list');

      // this.tree = []
      //   .concat(_.map(meta_group, o => _.assign({}, o, { icon: 'el-icon-folder', label: o.name, type: 2, node_key: `2-${o.id}`, parent_node_key: o.parent_id ? `2-${o.parent_id}` : 'root' })))
      //   .concat(_.map(meta, o => _.assign({}, o, { icon: o.model_icon, label: o.name, type: 3, node_key: `3-${o.id}` }, o.group_id ? { parent_node_key : `2-${o.group_id}` } : {})))
    },

    async loadTree() {

    },
    handleNodeClick(e) {
      this.currentNode = e;
    },
    handleNodeExpand(e) {
      this.expandedKeys = _.unionBy(this.expandedKeys, [e.node_key]);
    },

    handleNodeCollapse(e) {
      this.expandedKeys = _.difference(this.expandedKeys, [e.node_key]);
    },

    async loadNode(node, resolve) {
      let list = [];
      if(node.level === 0) {
        list = [{ icon: 'el-icon-folder', label: '业务元数据', type: 1, node_key: '' }];
      } else {
        if(node.data.type === 3) {
          const tree = [];
          const { list: meta } = await this.$api.execute('meta.list', { parent_id: node.data.id });
          tree.push(
            ..._.map(meta, o => _.assign({}, o, { icon: o.model_icon, label: o.name, type: 3, node_key: `3-${o.id}` }))
          );
          list = _(tree).map(o => _.assign({}, o, { isLeaf: !(o.model_show_compose && o.has_compose) })).value();
          if(node.data.model_show_compose_group) {
            list = _(list).groupBy('model_id').map(children => {
              const node_key = `4-${children[0].parent_id}-${children[0].model_id}`;
              return {
                node_key,
                label: children[0].model_name,
                type: 4,
                children,
                ..._.pick(children[0], ['model_id', 'parent_id'])
              }
            }).value()
          }
        } else if(node.data.type === 4) {
          list = node.data.children;
        } else {
          const tree = [];
          if(node.data.node_key === '') {
            const [{ list: meta_group }] = await this.$api.executeArray(
              ['meta_group.list', { parent_id: node.data.id || null }],
            );
            tree.push(
              ..._.map(meta_group, o => _.assign({}, o, { icon: 'el-icon-folder', label: o.name, type: 2, node_key: `2-${o.id}` }))
            )
          } else {
            const [{ list: meta_group }, { list: meta }] = await this.$api.executeArray(
              ['meta_group.list', { parent_id: node.data.id || null }],
              ['meta.list', { group_id: node.data.id || null } ]
            );
            tree.push(
              ..._.map(meta_group, o => _.assign({}, o, { icon: 'el-icon-folder', label: o.name, type: 2, node_key: `2-${o.id}` })),
              ..._.map(meta, o => _.assign({}, o, { icon: o.model_icon, label: o.name, type: 3, node_key: `3-${o.id}` }))
            );
          }
          
          list = _(tree).map(_.cond([
            [_.matches({ type: 2 }), o => _.assign({}, o, { isLeaf: !(o.has_children || o.has_meta)})],
            [_.matches({ type: 3 }), o => _.assign({}, o, { isLeaf: !(o.model_show_compose && o.has_compose) })],
            [_.stubTrue, _.identity]
          ])).value()
        }
      }
    

      if(this.initCurrentKey !== null && _.find(list, { node_key: this.initCurrentKey})) {
        const key = this.initCurrentKey;
        
        this.$nextTick(() => {
          this.$refs.tree.setCurrentKey(key);
        });
        this.initCurrentKey = null;
      }
      resolve(list);
    },
  }
}
</script>