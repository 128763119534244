<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="600px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item label="元数据名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="元数据代码">
        <el-input v-model="form.code"></el-input>
      </el-form-item>
      <el-form-item label="元模型">
        <el-cascader v-model="form.metaModelPath" :options="metaModelTree" style="width: 100%;" />
      </el-form-item>
      <el-form-item label="描述">
        <el-input v-model="form.description" type="textarea" resize="none" :rows="3" />
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import _ from 'lodash';

export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {},

      metaModelTree: []
    }
  },

  methods: {
    async init() {
      const { list: meta_model_package } = await this.$api.execute('meta_model_package.list');
      const { list: meta_model } = await this.$api.execute('meta_model.list', { mount_pos: 1 });

      const buildTree = (list, parent_node_key) => {
        return _(list).filter({ parent_node_key}).map(o => {          
          const children = buildTree(list, o.node_key);
          return _.size(children) ? _.assign({}, o, { children }) : (_.startsWith(o.node_key, 'MODEL:') ? o : null)
        }).compact().value();
      }

      this.metaModelTree = buildTree([]
        .concat(_.map(meta_model_package, o => _.assign({}, o, { node_key: `MODEL_PACKAGE:${o.id}`, parent_node_key: o.parent_id ? `MODEL_PACKAGE:${o.parent_id}` : null, label: o.name, value: `MODEL_PACKAGE:${o.id}` })))
        .concat(_.map(meta_model, o => _.assign({}, o, { node_key: `MODEL:${o.id}`, parent_node_key: `MODEL_PACKAGE:${o.package_id}`, label: o.name, value: o.id })))
      , null);


      if(this.form.model_id) {
        const mapPath = p => o => {
          const current = _.assign({}, o, {
            path: p ? [...p.path, o.value] : [o.value],
          });
          const children = _(o.children).map(mapPath(current)).flatten().value()
          return [current, ...children]
        }
        this.form.metaModelPath = _.find(_(this.metaModelTree).map(mapPath()).flatten().value(), { value: this.form.model_id })?.path || [];
      }

    },
    show(initForm, options = {}) {
      this.form = _.assign({}, initForm, {});
      this.options = options;
      this.init();
      this.visible = true;
    },

    async confirmForm() {
      try {
        if(this.form.id) {
          await this.$api.execute('meta.update', this.form.id, _.omit(this.form, ['id']));
        } else {
          await this.$api.execute('meta.create', _.assign({}, this.form, { model_id: _.last(this.form.metaModelPath) }));
        }
        this.options.success && this.options.success();
        this.visible = false;
      } catch(e) {
        this.$message.error(e.message);
      }
    },
  }
}
</script>