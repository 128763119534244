<template>
  <el-card>
    <div style="margin-bottom: 15px;">
      <el-button @click="createMetaGroup">新分组</el-button>
      <el-button v-if="node && node.id" @click="createMeta">新元数据</el-button>
      <el-button v-if="node && node.id" @click="updateMetaGroup(node)">编辑当前分组</el-button>
    </div>
    <el-table :data="tableData" border height="calc(100vh - 400px)">
      <el-table-column prop="name" label="名称" width="180" />
      <el-table-column prop="code" label="代码" width="180" />
      <el-table-column label="元数据类型" width="180">
        <template slot-scope="{row}">
          <span v-if="row.model_id">{{row.model_name}}({{row.model_code}})</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="180" />
      <el-table-column prop="update_time" label="更新时间" width="180" />
      <el-table-column label="操作" width="100">
        <template slot-scope="{row}">
          <el-button v-if="row.type === 1" @click="updateMetaGroup(row)" type="text" size="small">编辑</el-button>
          <el-button v-if="row.type === 1" @click="removeMetaGroup(row)" type="text" size="small">删除</el-button>
          <el-button v-if="row.type === 2" @click="updateMeta(row)" type="text" size="small">编辑</el-button>
          <el-button v-if="row.type === 2" @click="removeMeta(row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <a-meta-group-edit-form ref="metaGroupEditForm" />
    <a-meta-edit-form ref="metaEditForm" />
  </el-card>
</template>
<script>
import _ from 'lodash';
import AMetaGroupEditForm from './MetaGroupEditForm';
import AMetaEditForm from './MetaEditForm';

export default {
  components: { AMetaGroupEditForm, AMetaEditForm },
  props: {
    node: Object
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 20,
      total: 100
    }
  },
  watch: {
    node() {
      this.refresh()
    }
  },
  created() {
    this.refresh()
  }, 

  methods: {
    createMetaGroup() {
      this.$refs.metaGroupEditForm.show({ parent_id: this.node.id }, { title: '新增分组', success: () => {
        this.refresh();
        this.$emit('success');
      }});
    },
    updateMetaGroup(obj) {
      this.$refs.metaGroupEditForm.show(obj, { title: '编辑分组', success: () => {
        this.refresh();
        this.$emit('success');
      }});
    },

    async removeMetaGroup(obj) {
      try {
        await this.$confirm('此操作将永久删除该分组与该分组下的全部数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        await this.$api.execute('meta_group.remove', obj.id)

        this.refresh();
        this.$emit('success')
        this.$message({ type: 'success', message: '删除成功!' });
      } catch(e) {
        if(e !== 'cancel') { 
          this.$message.error(e.message);
        }
      }
    },

    createMeta() {
      this.$refs.metaEditForm.show({ group_id: this.node.id }, { title: '新增元数据', success: () => {
        this.refresh();
        this.$emit('success');
      }});
    },

    updateMeta(obj) {
      this.$refs.metaEditForm.show(obj, { title: '编辑元数据', success: () => {
        this.refresh();
        this.$emit('success');
      }});
    },

    async removeMeta(obj) {
      try {
        await this.$confirm('此操作将永久删除该元数据与该元数据下的全部数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        await this.$api.execute('meta.remove', obj.id)

        this.refresh();
        this.$emit('success')
        this.$message({ type: 'success', message: '删除成功!' });
      } catch(e) {
        if(e !== 'cancel') { 
          this.$message.error(e.message);
        }
      }
    },

    async refresh() {
      if(this.node.id) {
        const [{ list: meta_group }, { list: meta }] = await this.$api.executeArray(
          ['meta_group.list', { parent_id: this.node.id }],
          ['meta.list', { group_id: this.node.id }]
        )
        this.tableData = []
          .concat(_.map(meta_group, o => _.assign({}, o, { type: 1 })))
          .concat(_.map(meta, o => _.assign({}, o, { type: 2 })))
      } else {
        const { list: meta_group } = await this.$api.execute('meta_group.list', { parent_id: null });

        this.tableData = []
          .concat(_.map(meta_group, o => _.assign({}, o, { type: 1 })))
      }
    }
  }
}
</script>