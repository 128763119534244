<template>
  <div>
    <el-form v-if="attribute_list.length" ref="form" label-width="120px">
      <el-form-item v-for="o in attribute_list" :key="o.code" :label="o.name">
        {{attribute[o.code]}}
      </el-form-item>
    </el-form>
    <el-empty v-else></el-empty>
    

    <!-- <el-pagination
      style="margin-top: 20px; text-align: right;"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination> -->

    <edit-form ref="editForm" />
  </div>
</template>
<script>
// import _ from 'lodash';
import EditForm from './EditForm'
export default {
  components: { EditForm },
  props: {
    node: Object
  },
  data() {
    return {
      isShow: false,

      attribute_list: [],
      attribute: null
    }
  },
  watch: {
    node() {
      this.isShow && this.refresh()
    }
  },

  
  methods: {
    show() {
      this.isShow = true;
      this.refresh();
    },
    hide() {
      this.isShow = false;
    },

    async refresh() {

      try {
        const [{ list: attribute_list }, { list: [attribute]}] = await this.$api.executeArray(
          ['meta_model_attribute.list', { model_id: this.node.model_id }],
          ['table.list', `d_meta_attribute_${this.node.model_id}`, { meta_id: this.node.id }]
        );

        this.attribute_list = attribute_list;
        this.attribute = attribute;
      } catch(e) {
        this.loading = false;
        this.$message.error(e.message);
      }
    }
  }
}
</script>