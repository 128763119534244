<template>
  <div>
    <el-tabs type="border-card" v-model="tab" v-if="node">
      <el-tab-pane label="基本信息" name="pane1">
        <pane1 ref="pane1" :node="node" />
      </el-tab-pane>
      <el-tab-pane label="组合" name="pane2">
        <pane2 ref="pane2" :node="node" @success="$emit('success')" />
      </el-tab-pane>
      <el-tab-pane label="属性" name="pane3">
        <pane3 ref="pane3" :node="node" @success="$emit('success')" />
      </el-tab-pane>
      <el-tab-pane label="血缘关系" name="pane4">
        <pane4 ref="pane4" :node="node" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Pane1 from './Pane1';
import Pane2 from './Pane2';
import Pane3 from './Pane3';
import Pane4 from './Pane4';
export default {
  components: { Pane1, Pane2, Pane3, Pane4 },
  props: {
    node: Object
  },
  data() {
    return {
      tab: 'pane1',
      loading: false,
    }
  },
  watch: {
    tab(newValue, oldValue) {
			this.$refs[oldValue]?.hide?.call();
			this.$refs[newValue]?.show?.call();
    }
  },
  async created() {
		this.tab !== 'pane1' && this.$refs[this.tab]?.show?.call();
  },
}
</script>