<template>
  <div class="chart" style="height:calc(100vh - 300px);width: calc(100vw - 720px)" />
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize'
import _ from 'lodash'
export default {
  mixins: [resize],
  props: {
    node: Object
  },
  data() {
    return {
      isShow: false,
      chart: null,

      source_list: [],
      target_list: []
    }
  },
  watch: {
    node() {
      this.isShow && this.refresh()
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    show() {
      this.isShow = true;
      this.refresh();
    },
    hide() {
      this.isShow = false;
    },
    async refresh() {
      if(!this.node) return;
      
      const [{ list: source_list }, { list: target_list}] = await this.$api.executeArray(
        ['meta_relation.list', { target_meta_id: this.node.id }],
        ['meta_relation.list', { source_meta_id: this.node.id }]
      );

      let meta_list = (await this.$api.execute('meta.list', { ids: []
        .concat(this.node.id)
        .concat(..._.map(source_list, 'source_meta_id'))
        .concat(..._.map(target_list, 'target_meta_id'))
      })).list;
      if(_.size(meta_list)) {
        const parent_meta_list = (await this.$api.execute('meta.list', { ids: _(meta_list).map('parent_id').compact().value()})).list;
        meta_list = _.unionBy(meta_list, parent_meta_list, 'id');

        if(_.size(parent_meta_list)) {
          const parent_parent_meta_list = (await this.$api.execute('meta.list', { ids: _(parent_meta_list).map('parent_id').compact().value()})).list;
          meta_list = _.unionBy(meta_list, parent_parent_meta_list, 'id');
        }
      }


      const data = _([
        ..._(meta_list).filter({ id: this.node.id }).map((o, idx) => {
          const ret = [];
          let p = o;
          ret.push(_.assign({}, p, { name: `[${p.model_name}]${p.name}[${p.id}]`, x: 300, y: 20 + idx * 10, itemStyle: { color: '#5470c6' } }));
          p = _.find(meta_list, { id: p.parent_id });
          if(p) {
            ret.push(_.assign({}, p, { name: `[${p.model_name}]${p.name}[${p.id}]`, x: 320, y: 20 + idx * 10 - 20, itemStyle: { color: '#5470c6', opacity: 0.9 } }));
            p = _.find(meta_list, { id: p.parent_id });
            if(p) {
              ret.push(_.assign({}, p, { name: `[${p.model_name}]${p.name}[${p.id}]`, x: 340, y: 20 + idx * 10 - 40, itemStyle: { color: '#5470c6', opacity: 0.8 } }));
            }
          }

          return ret;
        }).flatten().value(),

        ..._(source_list).map((o, idx) => {
          const ret = [];
          let p = _.find(meta_list, { id: o.source_meta_id });
          ret.push(_.assign({}, p, { name: `[${p.model_name}]${p.name}[${p.id}]`, x: 200, y: 20 + idx * 10, itemStyle: { color: '#91cc75' } }))
          p = _.find(meta_list, { id: p.parent_id })

          if(p) {
            ret.push(_.assign({}, p, { name: `[${p.model_name}]${p.name}[${p.id}]`, x: 220, y: 20 + idx * 10 - 20, itemStyle: { color: '#91cc75', opacity: 0.9 } }));
            p = _.find(meta_list, { id: p.parent_id });
            if(p) {
              ret.push(_.assign({}, p, { name: `[${p.model_name}]${p.name}[${p.id}]`, x: 240, y: 20 + idx * 10 - 40, itemStyle: { color: '#91cc75', opacity: 0.8 } }));
            }
          }
          return ret;

        }).flatten().value(),

        ..._(target_list).map((o, idx) => {
          const ret = [];
          let p = _.find(meta_list, { id: o.target_meta_id });
          ret.push(_.assign({}, p, { name: `[${p.model_name}]${p.name}[${p.id}]`, x: 400, y: 20 + idx * 10, itemStyle: { color: '#fac858' } }))
          p = _.find(meta_list, { id: p.parent_id })

          if(p) {
            ret.push(_.assign({}, p, { name: `[${p.model_name}]${p.name}[${p.id}]`, x: 420, y: 20 + idx * 10 - 20, itemStyle: { color: '#fac858', opacity: 0.9 } }));
            p = _.find(meta_list, { id: p.parent_id });
            if(p) {
              ret.push(_.assign({}, p, { name: `[${p.model_name}]${p.name}[${p.id}]`, x: 440, y: 20 + idx * 10 - 40, itemStyle: { color: '#fac858', opacity: 0.8 } }));
            }
          }
          return ret;
        }).flatten().value()
      ]).value();

      const links = _([

        ..._(meta_list).filter({ id: this.node.id }).map((o) => {
          const ret = [];
          let source = _.findIndex(data, { id: o.id });
          let meta = o;
          if(meta && meta.parent_id) {
            let target = _.findIndex(data, { id: meta.parent_id });
            if(target !== -1) {
              ret.push({ source, target });

              meta = _.find(meta_list, { id: meta.parent_id });
              if(meta && meta.parent_id) {
                source = target;
                target = _.findIndex(data, { id: meta.parent_id });
                if(target !== -1) {
                  ret.push({ source, target });
                }
              }
            }
            
          }


          return ret;
        }).flatten().value(),

        ..._(source_list).map(o => {
          const ret = [];
          let source = _.findIndex(data, { id: o.source_meta_id });
          let target = _.findIndex(data, { id: o.target_meta_id });

          if(source !== -1 && target !== -1) {
            ret.push({ source, target, lineStyle: { width: 5 } })

            let meta = _.find(meta_list, { id: o.source_meta_id });
            if(meta && meta.parent_id) {
              // source = source;
              target = _.findIndex(data, { id: meta.parent_id });
              if(target !== -1) {
                ret.push({ source, target });

                meta = _.find(meta_list, { id: meta.parent_id });
                if(meta && meta.parent_id) {
                  source = target;
                  target = _.findIndex(data, { id: meta.parent_id });
                  if(target !== -1) {
                    ret.push({ source, target });
                  }
                }
              }
              
            }
          }

          return ret;
        }),

        ..._(target_list).map(o => {
          const ret = [];
          let source = _.findIndex(data, { id: o.source_meta_id });
          let target = _.findIndex(data, { id: o.target_meta_id });

          if(source !== -1 && target !== -1) {
            ret.push({ source, target, lineStyle: { width: 5 } })

            let meta = _.find(meta_list, { id: o.target_meta_id });
            if(meta && meta.parent_id) {
              source = target;
              target = _.findIndex(data, { id: meta.parent_id });
              if(target !== -1) {
                ret.push({ source, target });

                meta = _.find(meta_list, { id: meta.parent_id });
                if(meta && meta.parent_id) {
                  source = target;
                  target = _.findIndex(data, { id: meta.parent_id });
                  if(target !== -1) {
                    ret.push({ source, target });
                  }
                }
              }
              
            }
          }

          return ret;
        })

      ]).flatten().value();

      console.log({ links })

      this.source_list = source_list;
      this.target_list = target_list;
      this.chart = echarts.init(this.$el, 'macarons');
      
      
      this.chart.setOption({
        tooltip: {
          formatter: e => {
            return e.name.replace(/\[\d+\]/, '')
          }
        },
        animationDurationUpdate: 1500,
        animationEasingUpdate: 'quinticInOut',
        series: [
          {
            type: 'graph',
            layout: 'none',
            symbolSize: 80,
            roam: true,
            label: {
              show: true,
              formatter: e => {
                return e.name.replace(/\[\d+\]/, '')
              }
            },
            edgeSymbol: ['circle', 'arrow'],
            edgeSymbolSize: [4, 10],
            edgeLabel: {
              fontSize: 20
            },
            data,
            // data: [
            //   { name: this.node.name, x: 300, y: 20, itemStyle: { color: '#5470c6' } },
            //   ..._.map(source_list, (o, idx) => ({ name: `${o.source_meta_name}[${o.source_meta_id}]`, x: 200, y: 20 + idx * 20, itemStyle: { color: '#fac858' } })),

            //   ..._.map(target_list, (o, idx) => ({ name: `[${o.target_meta_model_name}] ${o.target_meta_name}[ID:${o.target_meta_id}]`, x: 400, y: 20 + idx * 20, itemStyle: { color: '#91cc75' } })),

            //   ..._.map(target_list, (o, idx) => ({ name: `[${o.target_meta_parent_model_name}] ${o.target_meta_parent_name}[${o.target_meta_id}]`, x: 500, y: 20 + idx * 20, itemStyle: { color: '#91cc75', opacity: 0.8 } })),
            // ],
            links,
            // links: [
            //   ..._.map(source_list, (o, idx) => ({ source: 1 + idx, target: 0 })),
            //   ..._.map(target_list, (o, idx) => ({ source: 0, target: source_list.length + 1 + idx })),
            //   ..._.map(target_list, (o, idx) => ({ source: source_list.length + 1 + idx, target: source_list.length + 1 + target_list.length + idx }))
            
            // ],
            // lineStyle: {
            //   opacity: 0.9,
            //   width: 2,
            //   curveness: 0
            // }
          }
        ]
      })
    }
  }
}
</script>
