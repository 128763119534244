<template>
  <div>
    <el-form ref="form" label-width="120px">
      <el-form-item label="元数据代码">
        {{node.code}}
      </el-form-item>
      <el-form-item label="元数据名称">
        {{node.name}}
      </el-form-item>
      <el-form-item label="元数据类型">
        {{node.model_name}}({{node.model_code}})
      </el-form-item>
      <el-form-item label="创建时间">
        {{node.create_time}}
      </el-form-item>
      <el-form-item label="更新时间">
        {{node.update_time}}
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    node: Object
  },
  data() {
    return {
      form: {},
      currentPage: 1,
      pageSize: 20,
      total: 100
    }
  },

  methods: {
  }
}
</script>