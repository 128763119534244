<template>
  <div>
    <el-dropdown style="margin-bottom: 15px;" v-if="meta_model_compose.length" trigger="click" @command="createRecord">
      <el-button>
        新建<i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="o in meta_model_compose" :key="o.compose_model_id" :command="o.compose_model_id">{{o.compose_model_name}}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-table v-loading="loading" :data="tableData" border height="calc(100vh - 400px)">
      <el-table-column prop="name" label="元数据名称" width="180" />
      <el-table-column prop="code" label="元数据代码" width="180" />
      <el-table-column prop="model_code" label="元数据类型" width="180">
        <template slot-scope="{row}">
          <span v-if="row.model_id">{{row.model_name}}({{row.model_code}})</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="180" />
      <el-table-column prop="update_time" label="更新时间" width="180" />
      <el-table-column label="操作" width="100">
        <template slot-scope="{row}">
          <el-button @click="updateRecord(row)" type="text" size="small">编辑</el-button>
          <el-button @click="removeRecord(row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- <el-pagination
      style="margin-top: 20px; text-align: right;"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination> -->

    <edit-form ref="editForm" />
  </div>
</template>
<script>
import _ from 'lodash';
import EditForm from './EditForm'
export default {
  components: { EditForm },
  props: {
    node: Object
  },
  data() {
    return {
      isShow: false,
      tableData: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
      loading: false,

      meta_model_compose: [],
    }
  },
  watch: {
    node() {
      if(this.isShow) {
        this.init();
        this.refresh()
      }
    }
  },
  
  methods: {
    async show() {
      this.isShow = true;
      this.init();
      this.refresh();
    },
    hide() {
      this.isShow = false;
    },
    async init() {
      const { list: meta_model_compose } = await this.$api.execute('meta_model_compose.list', { model_id: this.node.model_id });
      this.meta_model_compose = meta_model_compose;
    },
    createRecord(model_id) {
      const meta_model = _.find(this.meta_model_compose, { compose_model_id: model_id });
      this.$refs.editForm.show({ parent_id: this.node.id, model_id }, { title: `新增${meta_model.compose_model_name}`, success: () => {
        this.refresh();
        this.$emit('success')
      }});
    },
    updateRecord(obj) {
      this.$refs.editForm.show(obj, { title: `编辑${obj.model_name}`, success: () => {
        this.refresh();
        this.$emit('success')
      }});
    },

    async removeRecord(obj) {
      try {
        await this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        await this.$api.execute('meta.remove', obj.id)

        this.refresh();
        this.$emit('success')
        this.$message({ type: 'success', message: '删除成功!' });
      } catch(e) {
        if(e !== 'cancel') { 
          this.$message.error(e.message);
        }
      }
    },

    async refresh() {
      try {
        this.loading = true;
        const res = await this.$api.execute('meta.list', { parent_id: this.node.id });
        this.tableData = res.list;
        // this.total = res.count;
        this.loading = false;
      } catch(e) {
        this.loading = false;
        this.$message.error(e.message);
      }
    }
  }
}
</script>