<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="600px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item label="名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  
import _ from 'lodash';
export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {},
    }
  },

  methods: {
    show(initForm, options = {}) {
      this.form = _.assign({}, initForm, {});
      this.options = options;
      this.visible = true;
    },

    async confirmForm() {
      try {
        if(this.form.id) {
          await this.$api.execute('meta_group.update', this.form.id, _.omit(this.form, ['id']));
        } else {
          await this.$api.execute('meta_group.create', this.form);
        }
        this.options.success && this.options.success();
        this.visible = false;
      } catch(e) {
        this.$message.error(e.message);
      }
    },
  }
}
</script>