<template>
  <el-card>
    <el-table :data="tableData" border height="calc(100vh - 400px)">
      <el-table-column prop="name" label="名称" width="180" />
      <el-table-column prop="code" label="代码" width="180" />
      <el-table-column prop="model_code" label="元数据类型" width="180">
        <template slot-scope="{row}">
          <span v-if="row.model_id">{{row.model_name}}({{row.model_code}})</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="180" />
      <el-table-column prop="update_time" label="更新时间" width="180" />
      <el-table-column label="操作" width="100">
        <template slot-scope="{row}">
          <el-button @click="removeRecord(row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>
<script>
// import _ from 'lodash';

export default {
  props: {
    node: Object
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 20,
      total: 100
    }
  },
  watch: {
    node() {
      this.refresh()
    }
  },
  created() {
    this.refresh()
  }, 

  methods: {
    async refresh() {
      const { list: meta } = await this.$api.execute('meta.list', { parent_id: this.node.parent_id, model_id: this.node.model_id });
      this.tableData = []
        .concat(meta)
    },

    async removeRecord(obj) {
      try {
        await this.$confirm('此操作将永久删除该元数据与该元数据下的全部数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        await this.$api.execute('meta.remove', obj.id)

        this.refresh();
        this.$emit('success')
        this.$message({ type: 'success', message: '删除成功!' });
      } catch(e) {
        if(e !== 'cancel') { 
          this.$message.error(e.message);
        }
      }
    },
  }
}
</script>